import { Link, useLocation, useNavigate } from "react-router-dom";
import { TreeView, TreeItem } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import CelebrationIcon from "@mui/icons-material/Celebration";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PhoneIcon from "@mui/icons-material/Phone";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import BadgeIcon from "@mui/icons-material/Badge";
// import { ExpandMoreIcon, ChevronRightIcon } from "@mui/icons-material";
import LawTable from "./Law/LawConferenceTable";
import "./Sidebar.css";
import React, { useState, useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Modal,
  Table,
  Pagination,
  DatePicker,
  Tooltip,
} from "antd";
import { notify_error, notify_success } from "./Notify";
import { serverAddress } from "../constant";
import cookie from "js-cookie";
import UserAPI from "../utils/api/UserAPI";

const SideBar = (props) => {
  const [profileimage, setImage] = useState(cookie.get("image"));
  const [open, setOpen] = useState(false);
  let fname = cookie.get("firstname");
  let lname = cookie.get("lastname");
  let _id = cookie.get("user");
  // let roles = cookie.get("role").split(',');
  // const Admin = role == "super_admin";
  // const Admission = role == "super_admin" || role == "admission";
  // const Reception =
  //   role == "super_admin" || role == "reception" || role == "counsellor";
  // const Event = role == "super_admin" || role == "event";

  const role = props.role;
  const feature_access = props.feature;

  const hasRole = (targetRole) => role.includes(targetRole);

  console.log(role, feature_access);

  const navigate = useNavigate();
  const location = useLocation();
  const [enquiry, setEnquiry] = useState({
    firstname: "",
    lastname: "",
    file: null,
    _id: _id,
  });
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    // Dynamically inject CSS for scrollbar hiding, only for the sidebar
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .custom-sidebar::-webkit-scrollbar { display: none; }  /* For Chrome, Safari */
      .custom-sidebar { -ms-overflow-style: none; scrollbar-width: none; }  /* For IE, Firefox */
    `;
    document.head.appendChild(styleElement);

    // Clean up the style when the component is unmounted
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const isActive = (pathname) => {
    return location.pathname === pathname;
  };

  return (
    <nav
      className={`sidebar sidebar-offcanvas custom-sidebar ${
        collapsed ? "sidebar-collapsed" : ""
      }`}
      id="sidebar"
      style={{
        marginTop: "70px",
        width: collapsed ? "65px" : "310px",
        // position: "absolute",
        transition: "width 0.3s", // Smooth transition for width change
        height: "100vh",
        overflowY: "auto",
        // display: "flex",
        // alignItems: "flex-start",
      }}
    >
      <ul className="nav" style={{ backgroundColor: "#00173D" }}>
        {/* Sidebar Toggle Button */}
        <li className="d-flex justify-content-center my-2">
          <button
            className={` ${collapsed ? "toggle-button-large" : ""}`}
            onClick={toggleCollapse}
            style={{
              marginRight: "5px",
              backgroundColor: "#00173D",
              border: "none",
            }}
          >
            <i
              className={`mdi ${collapsed ? "mdi-menu" : "mdi-chevron-left"}`}
              style={{ color: "#fff", fontSize: collapsed ? "30px" : "30px" }}
            ></i>
          </button>
        </li>

        {/* Dashboard */}
        <li
          className={`nav-item  ${location.pathname === "/" ? "active" : ""}`}
        >
          <Link
            className="nav-link"
            to="/dashboard/"
            aria-expanded="false"
            aria-controls="ui-basic"
          >
            <HomeIcon
              fontSize="large"
              className=""
              style={{ marginRight: "15px" }}
            />
            {!collapsed && <span className="menu-title">Dashboard</span>}
          </Link>
        </li>

        {/* Admissions */}

        {hasRole("super_admin") ||
        hasRole("admission") ||
        hasRole("reception") ||
        hasRole("counsellor") ? (
          <li
            className={`nav-item ${
              location.pathname === "/dashboard/enquiry-table" ? "active" : ""
            }`}
          >
            <Link
              className="nav-link"
              to="/dashboard/enquiry-table"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <SchoolIcon
                fontSize="large"
                className=""
                style={{ marginRight: "15px" }}
              />
              {!collapsed && <span className="menu-title">Admissions</span>}
            </Link>
          </li>
        ) : null}

        {/* College Dekho */}
        {hasRole("super_admin") || hasRole("College Dekho") ? (
          <li
            className={`nav-item ${
              location.pathname === "/dashboard/college-dekho-table"
                ? "active"
                : ""
            }`}
          >
            <Link
              className="nav-link"
              to="/dashboard/college-dekho-table"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <HistoryEduIcon
                fontSize="large"
                className=""
                style={{ marginRight: "15px" }}
              />
              {!collapsed && <span className="menu-title">College Dekho</span>}
            </Link>
          </li>
        ) : null}

        {/* Events */}

        {hasRole("super_admin") ? (
          <li
            className={`nav-item ${
              [
                "/dashboard/law-conference",
                "/dashboard/iecegn-table",
                "/dashboard/icds",
                "/dashboard/ullas-registration",
                "/dashboard/psbm-table",
                "/dashboard/alumni-registration",
                "/dashboard/mic-2025",
              ].includes(location.pathname)
                ? "active"
                : ""
            }`}
          >
            <Link
              className="nav-link"
              data-bs-toggle="collapse"
              to="#ui-basic5-events"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <CelebrationIcon
                fontSize="large"
                className=""
                style={{ marginRight: "15px", pointerEvents: "none" }}
              />
              {!collapsed && <span className="menu-title">Events</span>}
            </Link>
            <div className="collapse" id="ui-basic5-events">
              <ul className="nav flex-column sub-menu">
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/icds" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/icds") ? "active-link" : ""
                    }`}
                    to="/dashboard/icds"
                  >
                    ICDS
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/iecegn-table"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/iecegn-table") ? "active-link" : ""
                    }`}
                    to="/dashboard/iecegn-table"
                  >
                    IECEGN
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/psbm-table"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/psbm-table") ? "active-link" : ""
                    }`}
                    to="/dashboard/psbm-table"
                  >
                    PSBM
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/nsat" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/nsat") ? "active-link" : ""
                    }`}
                    to="/dashboard/nsat"
                  >
                    NSAT
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/jobfair-details"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/jobfair-details")
                        ? "active-link"
                        : ""
                    }`}
                    to="/dashboard/jobfair-details"
                  >
                    JOB-FAIR
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/law-conference"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/law-conference") ? "active-link" : ""
                    }`}
                    to="/dashboard/law-conference"
                  >
                    Law-Conference
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/ullas-registration"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/ullas-registration")
                        ? "active-link"
                        : ""
                    }`}
                    to="/dashboard/ullas-registration"
                  >
                    Ullas
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/alumni-registration"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/alumni-registration")
                        ? "active-link"
                        : ""
                    }`}
                    to="/dashboard/alumni-registration"
                  >
                    Alumni Registration
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/mic-2025"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/mic-2025")
                        ? "active-link"
                        : ""
                    }`}
                    to="/dashboard/mic-2025"
                  >
                    MIC 2025
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        ) : (
          // Render based on feature_access for other roles
          <li
            className={`nav-item ${
              [
                "/dashboard/law-conference",
                "/dashboard/iecegn-table",
                "/dashboard/icds",
                "/dashboard/ullas-registration",
                "/dashboard/psbm-table",
                "/dashboard/alumni-registration",
                "/dashboard/mic-2025",
              ].includes(location.pathname)
                ? "active"
                : ""
            }`}
          >
            <Link
              className="nav-link"
              data-bs-toggle="collapse"
              to="#ui-basic5-events"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <CelebrationIcon
                fontSize="large"
                className=""
                style={{ marginRight: "15px", pointerEvents: "none" }}
              />
              {!collapsed && <span className="menu-title">Events</span>}
            </Link>
            <div className="collapse" id="ui-basic5-events">
              <ul className="nav flex-column sub-menu">
                {feature_access !== "" && feature_access.includes("icds") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/icds" ? "active" : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/icds") ? "active-link" : ""
                      }`}
                      to="/dashboard/icds"
                    >
                      ICDS
                    </Link>
                  </li>
                )}
                {feature_access !== "" && feature_access.includes("iecegn") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/iecegn-table"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/iecegn-table") ? "active-link" : ""
                      }`}
                      to="/dashboard/iecegn-table"
                    >
                      IECEGN
                    </Link>
                  </li>
                )}
                {feature_access !== "" && feature_access.includes("psbm") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/psbm-table"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/psbm-table") ? "active-link" : ""
                      }`}
                      to="/dashboard/psbm-table"
                    >
                      PSBM
                    </Link>
                  </li>
                )}
                {feature_access !== "" && feature_access.includes("nsat") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/nsat" ? "active" : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/nsat") ? "active-link" : ""
                      }`}
                      to="/dashboard/nsat"
                    >
                      NSAT
                    </Link>
                  </li>
                )}
                {feature_access !== "" &&
                  feature_access.includes("jobfair") && (
                    <li
                      className={`nav-item ${
                        location.pathname === "/dashboard/jobfair-details"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        className={`nav-link ${
                          isActive("/dashboard/jobfair-details")
                            ? "active-link"
                            : ""
                        }`}
                        to="/dashboard/jobfair-details"
                      >
                        JOB-FAIR
                      </Link>
                    </li>
                  )}
                {feature_access !== "" &&
                  feature_access.includes("lawconference") && (
                    <li
                      className={`nav-item ${
                        location.pathname === "/dashboard/law-conference"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        className={`nav-link ${
                          isActive("/dashboard/law-conference")
                            ? "active-link"
                            : ""
                        }`}
                        to="/dashboard/law-conference"
                      >
                        Law-Conference
                      </Link>
                    </li>
                  )}
                {feature_access !== "" && feature_access.includes("ullas") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/ullas-registration"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/ullas-registration")
                          ? "active-link"
                          : ""
                      }`}
                      to="/dashboard/ullas-registration"
                    >
                      Ullas
                    </Link>
                  </li>
                )}
                {feature_access !== "" && feature_access.includes("alumni-registration") && (
                 <li
                 className={`nav-item ${
                   location.pathname === "/dashboard/alumni-registration"
                     ? "active"
                     : ""
                 }`}
               >
                 <Link
                   className={`nav-link ${
                     isActive("/dashboard/alumni-registration")
                       ? "active-link"
                       : ""
                   }`}
                   to="/dashboard/alumni-registration"
                 >
                   Alumni Registration
                 </Link>
               </li>
                )}
                {feature_access !== "" && feature_access.includes("mic-2025") && (
                  <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/mic-2025"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/mic-2025")
                        ? "active-link"
                        : ""
                    }`}
                    to="/dashboard/mic-2025"
                  >
                    MIC 2025
                  </Link>
                </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* alumni */}
        {hasRole("super_admin") ? (
          <li
            className={`nav-item ${
              [
                "/dashboard/bbaAlumni",
                "/dashboard/bcaAlumni",
                "/dashboard/ballb",
                "/dashboard/bcomllb",
                "/dashboard/mca",
                "/dashboard/mba",
                "/dashboard/bed",
                "/dashboard/bcom",
                "/dashboard/llb",
              ].includes(location.pathname)
                ? "active"
                : ""
            }`}
          >
            <Link
              className="nav-link"
              data-bs-toggle="collapse"
              to="#ui-basic5-alumni"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <BadgeIcon
                fontSize="large"
                className=""
                style={{ marginRight: "15px", pointerEvents: "none" }}
              />
              {!collapsed && <span className="menu-title">Alumni</span>}
            </Link>
            <div className="collapse" id="ui-basic5-alumni">
              <ul className="nav flex-column sub-menu">
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/bbaAlumni" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/bbaAlumni") ? "active-link" : ""
                    }`}
                    to="/dashboard/bbaAlumni"
                  >
                    BBA
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/bcaAlumni" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/bcaAlumni") ? "active-link" : ""
                    }`}
                    to="/dashboard/bcaAlumni"
                  >
                    BCA
                  </Link>
                </li>

                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/ballb" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/ballb") ? "active-link" : ""
                    }`}
                    to="/dashboard/ballb"
                  >
                    BA-LLB
                  </Link>
                </li>

                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/bcomllb" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/bcomllb") ? "active-link" : ""
                    }`}
                    to="/dashboard/bcomllb"
                  >
                    B.COM-LLB
                  </Link>
                </li>

                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/mca" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/mca") ? "active-link" : ""
                    }`}
                    to="/dashboard/mca"
                  >
                    MCA
                  </Link>
                </li>

                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/mba" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/mba") ? "active-link" : ""
                    }`}
                    to="/dashboard/mba"
                  >
                    MBA
                  </Link>
                </li>

                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/bed" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/bed") ? "active-link" : ""
                    }`}
                    to="/dashboard/bed"
                  >
                    B.Ed
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/llb" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/llb") ? "active-link" : ""
                    }`}
                    to="/dashboard/llb"
                  >
                    LLB
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/bcom" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/bcom") ? "active-link" : ""
                    }`}
                    to="/dashboard/bcom"
                  >
                    B.COM
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/dashboard/deled" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      isActive("/dashboard/deled") ? "active-link" : ""
                    }`}
                    to="/dashboard/deled"
                  >
                    D.EL.ED
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        ) : (
          // Render based on feature_access for other roles
          <li
            className={`nav-item ${
              [
                "/dashboard/bbaAlumni",
                "/dashboard/bcaAlumni",
                "/dashboard/ballb",
                "/dashboard/bcomllb",
                "/dashboard/mca",
                "/dashboard/mba",
                "/dashboard/bed",
                "/dashboard/bcom",
                "/dashboard/llb",
              ].includes(location.pathname)
                ? "active"
                : ""
            }`}
          >
            <Link
              className="nav-link"
              data-bs-toggle="collapse"
              to="#ui-basic5-alumni"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <BadgeIcon
                fontSize="large"
                className=""
                style={{ marginRight: "15px", pointerEvents: "none" }}
              />
              {!collapsed && <span className="menu-title">Alumni</span>}
            </Link>
            <div className="collapse" id="ui-basic5-alumni">
              <ul className="nav flex-column sub-menu">
                {feature_access !== "" && feature_access.includes("bba") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/bbaAlumni"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/bbaAlumni") ? "active-link" : ""
                      }`}
                      to="/dashboard/bbaAlumni"
                    >
                      BBA
                    </Link>
                  </li>
                )}
                {feature_access !== "" && feature_access.includes("bca") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/bcaAlumni"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/bcaAlumni") ? "active-link" : ""
                      }`}
                      to="/dashboard/bcaAlumni"
                    >
                      BCA
                    </Link>
                  </li>
                )}

                {feature_access !== "" && feature_access.includes("ballb") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/ballb" ? "active" : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/ballb") ? "active-link" : ""
                      }`}
                      to="/dashboard/ballb"
                    >
                      BA-LLB
                    </Link>
                  </li>
                )}
                {feature_access !== "" &&
                  feature_access.includes("bcomllb") && (
                    <li
                      className={`nav-item ${
                        location.pathname === "/dashboard/bcomllb"
                          ? "active"
                          : ""
                      }`}
                    >
                      <Link
                        className={`nav-link ${
                          isActive("/dashboard/bcomllb") ? "active-link" : ""
                        }`}
                        to="/dashboard/bcomllb"
                      >
                        B.COM-LLB
                      </Link>
                    </li>
                  )}
                {feature_access !== "" && feature_access.includes("mca") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/mca" ? "active" : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/mca") ? "active-link" : ""
                      }`}
                      to="/dashboard/mca"
                    >
                      MCA
                    </Link>
                  </li>
                )}
                {feature_access !== "" && feature_access.includes("mba") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/mba" ? "active" : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/mba") ? "active-link" : ""
                      }`}
                      to="/dashboard/mba"
                    >
                      MBA
                    </Link>
                  </li>
                )}
                {feature_access !== "" && feature_access.includes("bed") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/bed" ? "active" : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/bed") ? "active-link" : ""
                      }`}
                      to="/dashboard/bed"
                    >
                      B.Ed
                    </Link>
                  </li>
                )}
                {feature_access !== "" && feature_access.includes("llb") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/llb" ? "active" : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/llb") ? "active-link" : ""
                      }`}
                      to="/dashboard/llb"
                    >
                      LLB
                    </Link>
                  </li>
                )}
                {feature_access !== "" && feature_access.includes("bcom") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/bcom" ? "active" : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/bcom") ? "active-link" : ""
                      }`}
                      to="/dashboard/bcom"
                    >
                      B.COM
                    </Link>
                  </li>
                )}

                {feature_access !== "" && feature_access.includes("deled") && (
                  <li
                    className={`nav-item ${
                      location.pathname === "/dashboard/deled" ? "active" : ""
                    }`}
                  >
                    <Link
                      className={`nav-link ${
                        isActive("/dashboard/deled") ? "active-link" : ""
                      }`}
                      to="/dashboard/deled"
                    >
                      D.EL.ED
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </li>
        )}

        {/* Alumni */}

        {/* Careers */}

        {hasRole("super_admin") ? (
          <li
            className={`nav-item ${
              location.pathname === "/dashboard/job-application" ? "active" : ""
            }`}
          >
            <Link
              className="nav-link"
              to="/dashboard/job-application"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <BusinessCenterIcon
                fontSize="large"
                className=""
                style={{ marginRight: "15px" }}
              />
              {!collapsed && <span className="menu-title">Careers</span>}
            </Link>
          </li>
        ) : null}

        {/* Contact Us */}
        {hasRole("super_admin") ? (
          <li
            className={`nav-item ${
              location.pathname === "/dashboard/contact-us" ? "active" : ""
            }`}
          >
            <Link
              className="nav-link"
              to="/dashboard/contact-us"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <PhoneIcon
                fontSize="large"
                className=""
                style={{ marginRight: "15px" }}
              />
              {!collapsed && <span className="menu-title">Contact Us</span>}
            </Link>
          </li>
        ) : null}

        {/* Feedback */}

        {hasRole("super_admin") || hasRole("feedback") ? (
          <li
            className={`nav-item ${
              location.pathname === "/dashboard/ullas" ? "active" : ""
            }`}
          >
            <Link
              className="nav-link"
              to="/dashboard/feedback"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <ThumbUpAltIcon
                fontSize="large"
                className=""
                style={{ marginRight: "15px" }}
              />
              {!collapsed && <span className="menu-title">Feedback</span>}
            </Link>
          </li>
        ) : null}

        {/* Create User */}

        {hasRole("super_admin") ? (
          <li
            className={`nav-item ${
              location.pathname === "/dashboard/admins-corner" ? "active" : ""
            }`}
          >
            <Link
              className="nav-link"
              to="/dashboard/admins-corner"
              aria-expanded="false"
              aria-controls="ui-basic"
            >
              <ManageAccountsIcon
                fontSize="large"
                className=""
                style={{ marginRight: "15px" }}
              />
              {!collapsed && <span className="menu-title">Create User</span>}
            </Link>
          </li>
        ) : null}
      </ul>
    </nav>
  );
};

export default SideBar;
