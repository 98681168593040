import { data } from "autoprefixer";
import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, InputGroup, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import LawAPI from "../../utils/api/LawAPI";
import { format } from "date-fns";
import { Badge, Tooltip, Modal, Pagination } from "antd";
import axios from "axios";
// import { EnquiryContext } from "../../state/context/EnquiryProvider";
// import {  Pagination} from "antd";
// import {
//   PlusCircleOutlined,
//   RetweetOutlined,
//   MailOutlined,
// } from "@ant-design/icons";​
import moment from "moment";
import { serverAddress } from "../../constant";
import { notify_error, notify_success } from "../Notify";
import { verify } from "crypto";
import UserAPI from "../../utils/api/UserAPI";
import { useNavigate } from "react-router-dom";
import { LawContext } from "../../state/context/LawProvider";
import FeedbackAPI from "../../utils/api/FeedbackAPI";
import { FeedbackContext } from "../../state/context/FeedbackProvider";
import FilterFeedbackTable from "./FilterFeedbackTable";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ReportModal from "./ReportModal";

const FeedbackTable = () => {
  const navigate = useNavigate();

  const {
    applyFilter,
    filter,
    setFilter,
    recperpage,
    handlePageChange,
    SetRecPerPage,
    activepage,
    SetActivePage,
    totalData,
    setTotalData,
    data,
    setDate,
    activeFeedback,
    setActiveFeedback,
    search,
    SetSearch,
  } = React.useContext(FeedbackContext);
  const dispatch = useDispatch();
  const { top_loader } = bindActionCreators(actionCreators, dispatch);
  //   const [recperpage, SetRecPerPage] = useState(25);
  //   const [activepage, SetActivePage] = useState(1);
  //   const [totalData, setTotalData] = useState(0);
  const sno = recperpage * (activepage - 1);
  //   const [data, setDate] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [isReportModalOpen, setisReportModalOpen] = useState(false);
  const [filteredData, setFilteredData]= useState([]);

  let timer;

  const filterPanelRef = useRef(null);
  useEffect(() => {
    // Dynamically inject CSS for scrollbar hiding, only for the sidebar
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .custom-sidebar::-webkit-scrollbar { display: none; }  /* For Chrome, Safari */
      .custom-sidebar { -ms-overflow-style: none; scrollbar-width: none; }  /* For IE, Firefox */
    `;
    document.head.appendChild(styleElement);

    // Clean up the style when the component is unmounted
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  useEffect(() => {
    console.log(activeFeedback);
    top_loader(true, 45);
    getAllFeedback({ page: activepage, limit: recperpage });
    // eslint-disable-next-line
  }, []);
  //   function applyFilter(event) {
  //     SetRecPerPage(event.target.value);
  //     getAllLaw({ page: 1, limit: event.target.value });
  //     SetActivePage(1);
  //   }

  useEffect(() => {
    SetRecPerPage(25);
  }, []);

  const getAllFeedback = (params) => {
    setLoading(true);
    if(activeFeedback==='parent_feedback'){
      FeedbackAPI.getAllParentFeedback(params).then((result) => {
         console.log(result);
        if (result.data.status) {
          setDate(result.data.data);
          setFilteredData(result.data.data);
          setTotalData(result.data.total);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        } else {
          setDate([]);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        }
      });
    }
    else if (activeFeedback==='faculty_feedback'){
      FeedbackAPI.getAllFacultyFeedback(params).then((result) => {
        // console.log(result);
        if (result.data.status) {
          setDate(result.data.data);
          setFilteredData(result.data.data);
          setTotalData(result.data.total);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        } else {
          setDate([]);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        }
      });
    }
    else if(activeFeedback==='employer_feedback'){
      FeedbackAPI.getAllEmployerFeedback(params).then((result) => {
        // console.log(result);
        if (result.data.status) {
          setDate(result.data.data);
          setFilteredData(result.data.data);
          setTotalData(result.data.total);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        } else {
          setDate([]);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        }
      });
    }
    else if(activeFeedback==='alumni_feedback'){
      FeedbackAPI.getAllAlumniFeedback(params).then((result) => {
        // console.log(result);
        if (result.data.status) {
          setDate(result.data.data);
          setFilteredData(result.data.data);
          setTotalData(result.data.total);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        } else {
          setDate([]);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        }
      });
    }
    else if(activeFeedback ==='student_satisfaction'){
      FeedbackAPI.getAllStudentSatisfactionFeedback(params).then((result) => {
        // console.log(result);
        if (result.data.status) {
          setDate(result.data.data);
          setFilteredData(result.data.data);
          setTotalData(result.data.total);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        } else {
          setDate([]);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        }
      });
    }
    else if(activeFeedback ==='subject_feedback'){
      console.log('Fetching subject feedback using Axios');
      const url= serverAddress +`/subject_feedback/all?page=${params.page}&limit=${params.limit}`;
    
      axios.get(url)
          .then((response) => {
              setDate(response.data);
              setFilteredData(response.data);
              console.log('filtereddata',filteredData);
              setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
          })
          .catch((error) => {
              console.error('Error fetching subject feedback:', error);
          });
      
    
    }
    else{
      FeedbackAPI.getAllStudentFeedback(params).then((result) => {
        // console.log(result);
        if (result.data.status) {
          setDate(result.data.data);
          setTotalData(result.data.total);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        } else {
          setDate([]);
          setLoading(false);
          top_loader(true, 100);
          top_loader(false, 0);
        }
      });
    }
    
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterPanelRef.current &&
        !filterPanelRef.current.contains(event.target)
      ) {
        setIsFilterPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleRefresh = () => {
    // Clear search
    // SetSearch("");
    // setIsSearch(false);

    // Clear filters
    //setFilter([]);
    setFilter([
      { field: "feedbackType", selected: ["student_feedback"] },
      { field: "course", selected: [] },
      { field: "batch", selected: [] },
      { field: "campus", selected: [] },
      { field: "department", selected: [] },
      { field: "date", selected: { start: "", end: "" } },
    ]);
  
    // setIsFilter(false);
    // filterEmptySelected();

    // Clear Checkbox
    // setSelectedRows([]);

    // Fetch data again
    getAllFeedback({ page: activepage, limit: recperpage });
  };

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };
  //   function handlePageChange(page) {
  //     SetActivePage(page);
  //     getAllLaw({ page: page, limit: recperpage });
  // }


  const handleSearchChange = (e) => {
    
    const { value } = e.target;
    SetSearch(value);
    console.log(value);

    // If the search field is emptied, reload all records
    clearTimeout(timer);

   timer = setTimeout(() => {
    if (value.trim() === "") {
      getAllFeedback({ page: activepage, limit: recperpage });
    } else {
      fetchDataWithSearch(value.trim());
    }
  }, 300);
  };

  const downloadData = () => {
    let csvContent;
    if (activeFeedback === "student_feedback") {
      csvContent =
        "data:text/csv;charset=utf-8," +
        [...data.map(row => `${row.name}, ${row.batch}, ${row.course}, ${row.created_at}`)].join("\n");
    } else if (activeFeedback === "faculty_feedback") {
      csvContent =
        "data:text/csv;charset=utf-8," +
        [...data.map(row => `${row.name} ,${row.department} ,${row.email} ,${row.created_at}`)].join("\n");
    }else if (activeFeedback === "alumni_feedback") {
      csvContent =
        "data:text/csv;charset=utf-8," +
        [...data.map(row => `${row.name} , ${row.course}, ${row.batch} ,${row.email} ,${row.created_at}`)].join("\n");
    }
    else if (activeFeedback === "parent_feedback") {
      csvContent =
        "data:text/csv;charset=utf-8," +
        [...data.map(row => `${row.parent_name}, ${row.name} , ${row.course} ,${row.created_at}`)].join("\n");
    }
    else if (activeFeedback === "employer_feedback") {
      csvContent =
        "data:text/csv;charset=utf-8," +
        [...data.map(row => `${row.name}, ${row.designation} , ${row.email} ,${row.created_at}`)].join("\n");
    }
    else if (activeFeedback === "student_satisfaction") {
      csvContent =
        "data:text/csv;charset=utf-8," +
        [...data.map(row => `${row.parent_name}, ${row.name} , ${row.course} ,${row.created_at}`)].join("\n");
    }

    // Create a blob for the data
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${activeFeedback}_data.csv`);
    document.body.appendChild(link);

    // Trigger download
    link.click();
    document.body.removeChild(link);
  };

  const fetchDataWithSearch = async (searchTerm) => {
    try {
      // setLoading(true);
     // console.log(activeFeedback);
     if(activeFeedback !='subject_feedback'){

      const res = await FeedbackAPI.searchQuery(
        { search: searchTerm },
        activepage,
        recperpage,
        activeFeedback
      );


      setDate(res.data.data);
      setFilteredData(res.data.data);
      setTotalData(res.data.total);
      SetActivePage(1);
    }
    else{
      const url= serverAddress +`/subject_feedback/search?searchTerm=${searchTerm}`;
    
      axios.get(url)
          .then((response) => {
          setDate(response.data);});
      
    }

      // setLoading(false);
    } catch (error) {
      console.error("Error in handleSearch:", error);
      // setLoading(false);
    }
  };
  

  return (
    <>
      <Container
        fluid
        className="p-0 custom-sidebar"
        style={{
          backgroundColor: "#F2F5F7",
          fontFamily: "Inter",
          position: "relative",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Row style={{ marginInline: "10px" }}>
          <Col lg={12}>
            <div className="py-2" style={{ marginTop: "80px" }}>
              <div
                className="bg-white mx-0 my-3 rounded"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* Search button */}
                <div style={{ marginLeft: "12px", width: "25%" }}>
                  {" "}
                  {/* Set width to 20% */}
                  <InputGroup
                    className="my-2"
                    style={{
                      borderRadius: "8px", // Rounded corners
                      border: "1px solid #D1D5DB", // Light border
                      backgroundColor: "#F8F9FB", // Light background
                      height: "43px", // Fixed height
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#F8F9FB",
                        paddingLeft: "11px",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        height: "100%", // Ensures the icon container takes the full height
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {/* <img src="/assets/images/dashboard/search.png" alt="search icon" /> */}
                    </div>

                    <Form.Control
                      placeholder={
                        activeFeedback === 'subject_feedback' 
                        ? 'Search with Name and Admission Number' 
                        : 'Search In Feedback Table'
                      }
                      aria-label="Search"
                      name="search"
                      style={{
                        backgroundColor: "#F8F9FB",
                        border: "none", // No inner border
                        fontFamily: "Inter",
                        paddingLeft: "0px",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        fontSize: "16px", // Adjust font size
                        color: "#6B7280", // Placeholder color
                        height: "100%", // Ensures the input field takes the full height
                        fontSize: "12px",
                      }}
                      onChange={handleSearchChange}
                      value={search}
                    />
                  </InputGroup>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                  {/* Button Group */}
                  <div className="d-flex align-items-center mx-1 mt-2 mt-sm-0">
                    {/* Report Button */}
                    <div style={{ padding: "0", margin: "0" }}>
                      <Button
                        //onClick={() => setisReportModalOpen(true)}
                        onClick={downloadData}
                        style={{
                          backgroundColor: "#663399",
                          borderColor: "#663399",
                          width: "auto",
                          padding: "12px 16px",
                          height: "43px",
                          whiteSpace: "nowrap",
                          borderRadius: "8px",
                        }}
                      >
                        <i className="fa-solid fa-download me-2"></i> Report
                      </Button>
                    </div>

                    {/* Filter Button */}
                    <div className="d-flex align-items-center mx-2">
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        onClick={toggleFilterPanel}
                        style={{
                          backgroundColor: "#BBC5EA",
                          borderColor: "#BBC5EA",
                          color: "#000000",
                          height: "43px",
                          padding: "11px 15px",
                          borderRadius: "8px",
                          // marginLeft: "12px", // Added margin to give more space between buttons
                        }}
                      >
                        Filter&nbsp;
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>

                    {/* Refresh Icon */}
                    <div className="d-flex align-items-center mx-1">
                      <RefreshOutlinedIcon
                        fontSize="large"
                        color="disabled"
                        cursor="pointer"
                        onClick={handleRefresh}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="maintable "
                id="table-container"
                style={{ height: "auto", width: "auto" }}
              >
                <table className="col-md-12 enquiryTable px-3">
                  {activeFeedback === "student_feedback" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Course</th>
                        <th>Batch</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}
                  {activeFeedback === "faculty_feedback" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Department</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}
                  {activeFeedback === "employer_feedback" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name </th>
                        <th>Designation</th>
                        <th>Email</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}
                  {activeFeedback === "alumni_feedback" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Batch</th>
                        <th>Course</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}

                  {activeFeedback === "parent_feedback" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name(Parent)</th>
                        <th>Name(Ward)</th>
                        <th>Course</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}
                  {activeFeedback === "student_satisfaction" && (
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>S.No.</th>
                        <th>Name(Parent)</th>
                        <th>Name(Ward)</th>
                        <th>Course</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                  )}
                  {activeFeedback === "subject_feedback" &&(
                    <thead className="bg-transparent">
                      <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                        <th>Name</th>
                        <th>Admission no.</th>
                        <th>Mobile no.</th>
                        <th>Course</th>
                        <th>Semester</th>
                        <th>Total Feedbacks</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {data.length > 0 &&
                      data.map((item, index) => {
                        return (
                          <>
                            {item.feedbackType === "student_feedback" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=student_feedback`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${item?.name}`}</td>
                                <td>{item?.course?.toUpperCase()}</td>
                                <td>{item?.batch}</td>
                                <td className="d-flex flex-column">
                                  {item?.created_at.slice(0, 10)}
                                </td>
                              </tr>
                            )}
                            {item.feedbackType == "faculty_feedback" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=faculty_feedback`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${item.name}`}</td>
                                <td>{item.email}</td>
                                <td>{item.department}</td>
                                <td>{item.created_at.slice(0, 10)}</td>
                              </tr>
                            )}
                            {item.feedbackType == "employer_feedback" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=employer_feedback`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${
                                  item.name_of_organization || item.name
                                }`}</td>
                                <td>{item.designation}</td>
                                <td>{item.email}</td>
                                <td>{item.created_at.slice(0, 10)}</td>{" "}
                              </tr>
                            )}

                            {item.feedbackType == "alumni_feedback" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=alumni_feedback`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${item.name}`}</td>
                                <td>{item.email}</td>
                                <td>{item.batch}</td>
                                <td>{item.course}</td>
                                <td>{item.created_at.slice(0, 10)}</td>{" "}
                              </tr>
                            )}

                            {item.feedbackType == "parent_feedback" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=parent_feedback`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${item.parent_name}`}</td>
                                <td>{item.name}</td>
                                <td>{item.course}</td>
                                <td>{item.created_at.slice(0, 10)}</td>{" "}
                              </tr>
                            )}
                            {item.feedbackType == "student_satisfaction" && (
                              <tr
                                className="tableRows"
                                onClick={() =>
                                  navigate(
                                    `/dashboard/feedback/${item._id}?feedbackType=student_satisfaction`
                                  )
                                }
                              >
                                <td>{sno + index + 1}</td>
                                <td>{`${item?.parent_name || "N/A"}`}</td>
                                <td>{item?.name || "N/A"}</td>
                                <td>{item?.course || "N/A"}</td>
                                <td>{item?.created_at.slice(0, 10)}</td>{" "}
                              </tr>
                            )}
                            {item.feedbackType== "subject_feedback" && (
                        <tr
                          className="tableRows"
                          onClick={() =>
                            navigate(
                              `/dashboard/feedback/${item.admission_number}?feedbackType=subject_feedback`
                            )
                          }
                        >
                          <td>{item.student_name}</td>
                          <td>{`${item.admission_number || "N/A"}`}</td>
                          <td>{item.student_number || "N/A"}</td>
                          <td>{item?.program || "N/A"}</td>
                          <td>{item?.semester}</td>{" "}
                          <td>{item.total_feedbacks}</td>
                        </tr>
                      )}

                      
                          </>
                        );
                      })}
                    



                    {data.length == "0" && (
                      <>
                        <div className="d-flex align-items-center justify-content-center">
                          <h1 className="text-muted">NO DATA</h1>
                        </div>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="tableEnd"></div>
              <div className="col-md-12 form-inline py-2 tableFooter d-flex gap-5 align-items-center">
                <div className="col-md-4 d-flex gap-2 align-baseline">
                  <label
                    className="control-label col-md-4"
                    style={{
                      fontWeight: "700",
                      paddingTop: "5px",
                      paddingLeft: "25px",
                    }}
                  >
                    Rows per page :
                  </label>
                  {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
                  <Form.Select
                    className="form-control "
                    onChange={(event) => SetRecPerPage(event.target.value)}
                    // onChange={(event) => applyFilter(event)}
                    value={recperpage}
                    style={{
                      border: "solid",
                      borderWidth: "thin",
                      color: "#000",
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </Form.Select>
                </div>

                <div
                  className="col-md-8 d-flex gap-2"
                  style={{
                    justifyContent: "end",
                    alignItems: "center",
                    paddingRight: "60px",
                  }}
                >
                  <Pagination
                    total={totalData}
                    showSizeChanger={false}
                    current={activepage}
                    size="small"
                    // showQuickJumper
                    pageSize={recperpage}
                    onChange={(page, pageSize) => {
                      SetActivePage(page);
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div
        ref={filterPanelRef}
        className={`filter-panel${isFilterPanelOpen ? " open" : ""}`}
        id="handler-container"
        // Style the slide-out panel as per your requirement
        style={{
          position: "fixed",
          top: "0",
          right: `${isFilterPanelOpen ? "0" : "-100%"}`,
          width: "400px",
          height: "100vh",
          overflowY: "auto",
          background: "#fff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          transition: "right 0.3s ease-in-out",
        }}
      >
        <FilterFeedbackTable />
      </div>

      {isReportModalOpen && (
        <ReportModal onClose={() => setisReportModalOpen(false)} />
      )}
    </>
  );
};
export default FeedbackTable;
