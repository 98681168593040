import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state/index";
import PageHeader from "./PageHeader";
import EnquiryAPI from "../utils/api/EnquiryAPI";
import OnlineEnquiryAPI from "../utils/api/OnlineEnquiryAPI";
import ActionStatus from "./enquiry/ActionStatus";
import { notify_error, notify_success } from "./Notify";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import "./enquiry/EnquiryTable.css";
import { serverAddress } from "../constant";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import UserAPI from "../utils/api/UserAPI";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import axios from "axios";
import {
  Badge,
  Button,
  Modal,
  Input,
  Table,
  Typography,
  TextField,
  Pagination,
  DatePicker,
  Tooltip,
} from "antd";
import { Select } from "antd";
import Duplicates from "./Duplicates";
import { set } from "lodash";

const StudentProfile = ({ data, handleRefresh }) => {
  const [key, setKey] = useState("");
  const [handlers, setHandlers] = useState("");
  const { TextArea } = Input;
  const { Title } = Typography;
  const [selectedOrigin, setSelectedOrigin] = useState(
    data?.form_location || ""
  );

  const [activeTransfer, setActiveTransfer] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [goBackToTable, setGoBackToTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userRole, setUserRole] = useState("");

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [leadStatus, setLeadStatus] = useState("none"); // Default to "none"

  const handleLeadStatus = async () => {
    try {
      const response = await fetch(`${serverAddress}/website/enquiry/update/lead/status`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ _id:data._id, lead_status: leadStatus }),
      });

      if (!response.ok) throw new Error("Failed to update lead status");

      //alert("Lead status updated successfully!");
      notify_success("Lead status updated successfully!");
      setIsOpen(false); // Close modal after success
      setLeadStatus("none"); // Reset lead status to "none"
    } catch (error) {
      console.error(error);
      notify_error("Error updating lead status");
      setLeadStatus("none"); // Reset lead status to "none"
      setIsOpen(false); // Close modal on error
      //alert("Error updating lead status");
    }
  };
  const [isPaid, setIsPaid] = useState(false); // Default: Not Paid
  const [loading, setLoading] = useState(false);

  const handleRegistrationFees = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${serverAddress}/website/enquiry/update/lead/registration/fee/status`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ payment_status: isPaid, _id: data._id }),
        }
      );

      if (!response.ok) throw new Error("Failed to update registration fee status");

      //alert("Registration fee status updated successfully!");
      notify_success("Registration fee status updated successfully!");
      setIsOpen2(false); // Close modal after success
      setIsPaid(false); // Reset registration fee status to "Not Paid"
    } catch (error) {
      console.error(error);
      notify_error("Error updating registration fee status");
      setIsPaid(false); // Reset registration fee status to "Not Paid"
      setIsOpen2(false); // Close modal on error
      //alert("Error updating registration fee status");
    } finally {
      setLoading(false);
    }
  };


  const showModal = () => {
    setIsModalVisible(true);
    // console.log("42 check");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const originOptions = [
    { value: "get-admission-walk-in", label: "get-admission-walk-in" },
    { value: "college-dekho-walk-in", label: "college-dekho-walk-in" },
    { value: "other-consultant-walk-in", label: "other-consultant-walk-in" },
    { value: "website-pop-up", label: "website-pop-up" },
    { value: "online-query", label: "online-query" },
    { value: "walk-in", label: "walk-in" },
    { value: "ad-page", label: "ad-page" },
    { value: "telephonic", label: "telephonic" },
    { value: "college-dekho", label: "college-dekho" },
    { value: "existing-student", label: "existing-student" },
  ];

  const handleOriginUpdate = async () => {
    try {
      const params = {
        _id: data._id,
        origin: selectedOrigin,
      };

      const response = await OnlineEnquiryAPI.uploadOrigin(params);
      if (response.status) {
        notify_success("Origin Updated successfully");
        setIsModalVisible(false);
        handleRefresh();
      }
    } catch (error) {
      notify_success("Error updating origin:");
    }
  };

  const handleReassign = async (param) => {
    let role;
    if (data.form_location == "walk-in") {
      role = "counsellor";
      if (param.role != "counsellor") {
        notify_error("Only Counsellor can handle these enquiry");
        return;
      }
    } else if (data.form_location != "walk-in") {
      role = "admission";
    }

    const params = {
      _id: data._id,
      type: "one",
      newAssigned: `${param.firstname} ${param.lastname}`,
      user: cookie?.get("name"),
      role: role,
    };

    OnlineEnquiryAPI.assignHandlerOne(params)
      .then((res) => {
        notify_success("Enquiry Successfully Updated");
        setActiveTransfer(false);
      })
      .catch((err) => {
        notify_error(err.message);
      });
  };

  function handleTransfer() {
    const handleTransferLogic = async () => {
      const params = { page: 1, limit: 100 };

      try {
        const result = await UserAPI.getAllUsers(params);
        // console.log("---------data--------", result);

        // Filter users based on "counsellor" or "admission" roles
        const filteredUsers = result.data?.data?.filter(
          (user) =>
            user.role.includes("counsellor") || user.role.includes("admission")
        );

        setHandlers(filteredUsers);
      } catch (error) {
        // console.error("Error fetching users:", error);
      }
    };

    setActiveTransfer((prev) => !prev);
    handleTransferLogic();
  }

  useEffect(async () => {
    const params = { page: 1, limit: 100 };

    await UserAPI.getAllUsers(params)
      .then((result) => {
        const retrievedHandlers = result.data?.data.filter(
          (item) => item.role === "counsellor" || item.role === "admission"
        );
        setHandlers(retrievedHandlers);
        // console.log(retrievedHandlers); // Log the retrieved handlers
      })
      .catch((error) => {
        // console.error("Error fetching handlers:", error);
      });
  }, []);

  useEffect(() => {
    // console.log(handlers)
  }, [handlers]);

  useEffect(() => {
    const role = cookie.get("role"); // Assuming the role is stored in a cookie named "role"
    setUserRole(role);
  }, []);

  const handlePrint = (params) => {
    EnquiryAPI.updateStatus({ _id: params }).then((result) => {
      if (result.data.status) {
        notify_success(result.data.message);
        //   getAllEnquiry({ page: activepage, limit: recperpage });
        // window.open(result?.data?.downloadLink);
        let x = result?.data?.downloadLink;
        const url = new URL(x);
        const path = url.pathname.slice(1);
        setKey(path);
        window.open(
          `${serverAddress}/job_application/preview?file_path=${path}`
        );
      } else {
        notify_error("Failed to Print");
      }
    });
  };

  useEffect(() => {
     console.log(userRole) 
  }, );

  const handleAssignment = (event, _id) => {
    event.stopPropagation();
    event.preventDefault();

    const userRoles = cookie.get("role") ? cookie.get("role").split(",") : [];

    if (!userRoles.includes("counsellor")) {
      notify_error("Only Counsellors have access to walk-in Enquiries");
      return;
    }

    if (userRoles.includes("counsellor")) {
      console.log(userRole);
      if (userRole ==="College Dekho") {
        notify_error("Unauthorized Access. Only For Counsellors.");
        return;
      }else{
        EnquiryAPI.updateAssignment({
          _id: _id,
          username: cookie.get("name"),
        })
          .then((result) => {
            // console.log(result);
            if (result.data.status) {
              handleRefresh();
              notify_success(result.data.message);
              // getAllEnquiry({ page: activepage, limit: recperpage });
            } else {
              notify_error("Failed to update");
            }
          })
          .catch((err) =>
            notify_error("Unauthorized Access. Only For Counsellors.")
          );
      }

    }
  };

  function handleStatus(event, id) {
    event.stopPropagation();
    event.preventDefault();
    if (userRole === "College Dekho") {
      notify_error("Unauthorized Access. Only For Counsellors.");
      return;
    }else{
      OnlineEnquiryAPI.updateStatus({
        _id: id,
        username: cookie.get("name"),
      }).then((result) => {
        if (result.data.status) {
          handleRefresh();
          notify_success(result.data.message);
          // getAllEnquiry({ page: activepage, limit: recperpage });
        } else {
          notify_error("Failed to update");
        }
      });
    }

  }

  useEffect(() => {
    // console.log(data);
    // console.log(key);
  }, [key]);

  function getFirstWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase());
    return capitalizedWords;
  }
  function capitalizeWords(name) {
    const words = name.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  const handleRowSelection = (e, rowId) => {
    // Check if the clicked row is already selected
    if (selectedRowId === rowId) {
      // If the clicked row is the same as the selected one, navigate back to the table
      setGoBackToTable(true);
    } else {
      // If a different row is clicked, update the selectedRowId state
      setSelectedRowId(rowId);
      setGoBackToTable(false);
      // Your existing logic for handling row selection here
    }
  };

  const handleBackButton = () => {
    if (goBackToTable) {
      // If the flag is set to true, navigate back to the table on the same row
      navigate(`/dashboard/enquiry-table#${selectedRowId}`);
    } else {
      // If no row is selected, navigate back to the lead page
      navigate("/dashboard/enquiry-table");
    }
  };

  // const handlePrint = (params) => {
  //     EnquiryAPI.updateStatus({ _id: params }).then((result) => {
  //       if (result.data.status) {
  //         notify_success(result.data.message);
  //         getAllEnquiry({ page: activepage, limit: recperpage });
  //         // window.open(result?.data?.downloadLink);
  //         let x = result?.data?.downloadLink;
  //         const url = new URL(x);
  //         const path = url.pathname.slice(1);

  //         window.open(
  //           `${serverAddress}/ipem/job_application/preview?file_path=${path}`
  //         );
  //       } else {
  //         notify_error("Failed to Print");
  //       }
  //     });
  //   };

  // const downloadPdf = (params) => {
  //     let x = params;
  //     const url = new URL(x);
  //     const path = url.pathname.slice(1);

  //     window.open(
  //       `${serverAddress}/ipem/job_application/preview?file_path=${path}`
  //     );
  //   };

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    const dataSent = {
      mobileNumber: data.mobile_number,
      message: message,
    };
    try {
      await axios.post(
        "https://api-dev.asserit.io/ipem/website/enquiry/whatsapp",
        dataSent
      );
      alert("Message sent successfully!");
      setMessage("");
      handleClose();
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message.");
    }
  };

  return (
    <>
      {data && data._id && (
        <div className="spWrapper" style={{ height: "535px" }}>
          <div className="spContent">
            <div className="spContent01">{getFirstWords(data.name)}</div>

            <div className="spContent02">{capitalizeWords(data.name)}</div>
            <div className="spContent03">+91{data.mobile_number}</div>
          </div>

          <div className="spContent1">
            <img
              height={"20px"}
              src="/assets/images/dashboard/calender.png"
            ></img>
            <div className="spContent11">Created at:</div>
            <div className="spContent12">
              {new Intl.DateTimeFormat("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }).format(new Date(data.created_at))}
            </div>
          </div>

          <div className="spContent2"></div>

          <div className="spContent3">
            <div className="spContent31 mx-3">
              <div className="spContent32">Program :</div>
              <div className="spContent33">{data?.program.toUpperCase()}</div>
            </div>

            {data && data.email && (
              <div className="spContent31 mx-3">
                <div className="spContent32">E-mail :</div>
                <div className="spContent33">{data.email}</div>
              </div>
            )}
            <div className="spContent31 mx-3">
              <div className="spContent32">Mobile Number :</div>
              <div className="spContent33">{data?.mobile_number}</div>
            </div>

            <div className="spContent31 mx-3">
              <div className="spContent32">Origin :</div>
              <div className="spContent33">
                {data?.form_location.toUpperCase() || "N/A"}
              </div>
            </div>

            <div className="spContent31 mx-3">
              <div className="spContent32">Enquiry Type :</div>
              <div className="spContent33">{data?.enquiry_type}</div>
            </div>
            {data.enquiry_type !== "walk-in" && (
              <div className="spContent31 mx-3">
                <div className="spContent32">Enquiry Location :</div>
                <div className="spContent33">
                  {" "}
                  {` ${data?.state || "N/A"} ${data?.city || ""}`}
                </div>
              </div>
            )}

            {activeTransfer && (
              <div className="spContent31 mx-3">
                <div className="spContent32">Transfer Enquiry :</div>
                <div className="spContent33">
                  {/* <DropdownButton id="dropdown-basic-button" variant="info" size="medium"  title="Dropdown button">
                    {handlers && handlers.map((user) => (
                      <Dropdown.Item onClick={()=>handleReassign(user)} key={user.id}>{`${user.firstname} ${user.lastname}`}</Dropdown.Item>
                    ))}
                  </DropdownButton> */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="info"
                      size="xs"
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                        backgroundColor: "#663399",
                        color: "#fffff",
                        borderColor: "#663399",
                        border: "2px solid #663399",
                      }}
                    >
                      Transfer To
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                      }}
                    >
                      {handlers &&
                        handlers.map((user) => (
                          <Dropdown.Item
                            onClick={() => handleReassign(user)}
                            key={user.id}
                            className="transferTo"
                            style={{ backgroundColor: "#fffff", color: "#000" }}
                          >
                            {`${user.firstname
                              .charAt(0)
                              .toUpperCase()}${user.firstname.slice(1)} ${
                              user.lastname
                            }`}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}

            {!activeTransfer &&
              data.form_location == "walk-in" &&
              (data.assignedTo ? (
                <div className="spContent31 mx-3">
                  <div className="spContent32">Assigned To :</div>
                  <div className="spContent33">
                    {" "}
                    {` ${data?.assignedTo.toUpperCase()}  `}
                  </div>
                </div>
              ) : (
                <div className="spContent31 mx-3">
                  <div className="spContent32">Assigned To :</div>
                  <div className="spContent33">
                    {!data.assignedTo &&  (
                      <>
                        <a
                          className="bg-transparent border-0"
                          style={{
                            color: "#663399",
                            fontWeight: "600",
                            borderBottom: "1px solid #663399",
                          }}
                          onClick={(e) => handleAssignment(e, data._id)}
                          disabled={userRole === "College Dekho"}
                        >
                          Assign To Me
                        </a>
                      </>
                    )}
                  </div>
                </div>
              ))}

            {!activeTransfer &&
              data.form_location !== "walk-in" &&
              (data.handled_by ? (
                <div className="spContent31 mx-3">
                  <div className="spContent32">Handled By :</div>
                  <div className="spContent33">
                    {" "}
                    {` ${data?.handled_by.toUpperCase()}  `}
                  </div>
                </div>
              ) : (
                <div className="spContent31 mx-3">
                  <div className="spContent32">Handled By :</div>
                  <div className="spContent33">
                    {data.form_location !== "walk-in" && !data.handled_by &&  (
                      <>
                        <a
                          className="bg-transparent border-0"
                          style={{
                            color: "#663399",
                            fontWeight: "600",
                            borderBottom: "1px solid #663399",
                          }}
                          onClick={(e) => handleStatus(e, data._id)}
                          disabled={userRole === "College Dekho"}
                        >
                          Assign To Me
                        </a>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>

          <div className="spContent4">
            <div className="spContent41 ">
              <a>
                <WhatsAppIcon sx={{ color: "#AAACAD" }} fontSize="large" />
              </a>
            </div>

            <div className="spContent43">
              <button
                className="spButton1 py-1 px-3 me-2"
                style={{
                  border: "none",
                  borderRadius: "8px", // rounded corners
                  backgroundColor: "#663399", // purple background
                  color: "#fff", // white text
                  padding: "6px 12px", // adjust padding as needed
                }}
                onClick={() => handlePrint(data._id)}
                disabled={userRole === "College Dekho"}
              >
                Print Now
              </button>

             {/* <button
                className="spButton1 py-1 px-3 me-2"
                style={{
                  border: "none",
                  borderRadius: "8px", // rounded corners
                  backgroundColor: "#663399", // purple background
                  color: "#fff", // white text
                  padding: "6px 12px", // adjust padding as needed
                }}
                onClick={handleTransfer}
                disabled={userRole === "College Dekho"}
              >
                Transfer
              </button>

              <button
                className="spButton1 py-1 px-3 me-2"
                style={{
                  border: "none",
                  borderRadius: "8px", // rounded corners
                  backgroundColor: "#663399", // purple background
                  color: "#fff", // white text
                  padding: "6px 12px", // adjust padding as needed
                }}
                onClick={showModal}
                disabled={userRole === "College Dekho"}
              >
                Update Origin
              </button> */}
              <button
                className="spButton1 py-1 px-3 me-2"
                style={{
                  border: "none",
                  borderRadius: "8px", // rounded corners
                  backgroundColor: "#663399", // purple background
                  color: "#fff", // white text
                  padding: "6px 12px", // adjust padding as needed
                }}
                //onClick={handleTransfer}
                onClick={() => setIsOpen(true)}
                // disabled={userRole === "College Dekho"}
              >
                Update Lead
              </button>
              {isOpen && (
  <div
    style={{
      position: "fixed",
      inset: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000, // Ensure modal is above everything
    }}
  >
    <div
      style={{
        backgroundColor: "white",
        padding: "24px",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        width: "24rem", // Equivalent to w-96 in Tailwind (96 * 0.25rem = 24rem)
      }}
    >
      <h2
        style={{
          fontSize: "1.125rem", // Equivalent to text-lg
          fontWeight: "600", // Equivalent to font-semibold
          marginBottom: "12px",
        }}
      >
        Update Lead Status
      </h2>

      <select
        style={{
          width: "100%",
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: "6px",
          marginBottom: "16px",
        }}
        value={leadStatus}
        onChange={(e) => setLeadStatus(e.target.value)}
      >
        <option value="none">None</option>
        <option value="admission-taken">Admission Taken</option>
        <option value="in-process">In Process</option>
        <option value="not-interested">Not Interested</option>
      </select>

      <div
        style={{
          marginTop: "16px",
          display: "flex",
          justifyContent: "space-between",
          gap: "8px", // Equivalent to space-x-2
        }}
      >
        <button
          style={{
            backgroundColor: "#9CA3AF", // Equivalent to bg-gray-400
            color: "white",
            padding: "8px 16px",
            borderRadius: "6px",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => {setIsOpen(false); setLeadStatus("none")}}
        >
          Cancel
        </button>
        <button
          style={{
            backgroundColor: "#663399", // Equivalent to bg-purple-600
            color: "white",
            padding: "8px 16px",
            borderRadius: "6px",
            border: "none",
            cursor: "pointer",
          }}
          onClick={handleLeadStatus}
        >
          Submit
        </button>
      </div>
    </div>
  </div>
)}

              <button
                className="spButton1 py-1 px-3 me-2"
                style={{
                  border: "none",
                  borderRadius: "8px", // rounded corners
                  backgroundColor: "#663399", // purple background
                  color: "#fff", // white text
                  padding: "6px 12px", // adjust padding as needed
                }}
                onClick={() => setIsOpen2(true)}
                // disabled={userRole === "College Dekho"}
              >
                Update Registraion Fees
              </button>
              {isOpen2 && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "24px",
              borderRadius: "12px",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              width: "400px",
            }}
          >
            <h2 style={{ fontSize: "18px", fontWeight: "bold", textAlign:"center", marginBottom: "12px" }}>
              Update Registration Fee Status
            </h2>

            <div style={{ display: "flex", justifyContent:"center", gap: "16px", paddingRight:"16px",paddingLeft:"16px", marginTop: "16px" }}>
              <label style={{ display: "flex", alignItems: "center", gap: "8px", fontSize:"16px" }}>
                <input
                  type="radio"
                  name="registration_fee"
                  value="true"
                  checked={isPaid === true}
                  onChange={() => setIsPaid(true)}
                />
                <span>Paid</span>
              </label>

              <label style={{ display: "flex", alignItems: "center", gap: "8px", fontSize:"16px" }}>
                <input
                  type="radio"
                  name="registration_fee"
                  value="false"
                  checked={isPaid === false}
                  onChange={() => setIsPaid(false)}
                />
                <span>Not Paid</span>
              </label>
            </div>

            <div style={{ marginTop: "16px", display: "flex", justifyContent: "space-between", gap: "8px" }}>
              <button
                style={{
                  backgroundColor: "#a0a0a0",
                  color: "#fff",
                  padding: "8px 16px",
                  borderRadius: "6px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => {setIsOpen2(false); setIsPaid(false)}}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                style={{
                  backgroundColor: "#663399",
                  color: "#fff",
                  padding: "8px 16px",
                  borderRadius: "6px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleRegistrationFees}
                disabled={loading}
              >
                {loading ? "Updating..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      )}
            </div>
          </div>

          {/* Modal component */}
          <Modal
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={[
              <button
                key="update"
                className="spButton1 py-1 px-2 me-3 mb-2"
                style={{ border: "none" , width:"80px"}}
                onClick={handleOriginUpdate}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                disabled={!selectedOrigin} // Disable button if no origin is selected
              >
                Update
              </button>,
              <button
                key="cancel"
                className="spButton2 py-1 px-2 me-2 mb-2"
                style={{ border: "none" , width:"80px"}}
                onClick={handleCancel}
              >
                Cancel
              </button>,
            ]}
          >
            {/* Dropdown for selecting origin */}
            <Select
              value={selectedOrigin} // Bind the selected value
              onChange={(value) => setSelectedOrigin(value)}
              style={{ width: "100%" }}
              className="mt-4"
              placeholder="Select an origin" // Placeholder text
              allowClear // Optional: Allow clearing the selection
            >
              {originOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Modal>
        </div>
      )}

      <Modal visible={open} onCancel={handleClose} footer={null} centered>
        <div style={{ padding: 24 }}>
          <Title level={4}>Send a Message</Title>
          <TextArea
            placeholder="Message"
            autoSize={{ minRows: 4 }}
            value={message}
            onChange={handleMessageChange}
            style={{ marginTop: 16, marginBottom: 16 }}
          />
          <Button type="primary" onClick={handleSendMessage}>
            Send
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default StudentProfile;
