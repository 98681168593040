import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Modal,
  Table,
  Pagination,
  DatePicker,
  Tooltip,
} from "antd";
import { Form } from "react-bootstrap";
import moment from "moment";
import "antd/dist/antd.css";
import EnquiryAPI from "../utils/api/EnquiryAPI";
import { notify_error, notify_success } from "./Notify";
import "primeicons/primeicons.css";

function CreateEnquiry({ open, setOpen }) {
  // const [open, setOpen] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isFormValidate, setFormValidate] = useState(false);

  const [enquiry, setEnquiry] = useState({
    program: "",
    name: "",
    email: "",
    mobile_number: "",
    form_location: "",
    enquiry_type: "",
    source: "",
    reference: "",
    state: "",
    city: "",
  });
  const [isTelephonic, setIsTelephonic] = useState(false);

  useEffect(() => {
    if (enquiry.form_location === "telephonic") {
      setEnquiry((prev) => ({ ...prev, enquiry_type: "telephonic" }));
      setIsTelephonic(true);
    } else if (enquiry.form_location === "walk-in") {
      setEnquiry((prev) => ({ ...prev, enquiry_type: "organic" }));
      setIsTelephonic(false);
    } else if (enquiry.form_location === "existing-student") {
      setEnquiry((prev) => ({ ...prev, enquiry_type: "existing-student" }));
      setIsTelephonic(false);
    }
  }, [enquiry.form_location]);

  const handleOnEnquiryChange = (e) => {
    const { name, value } = e.target;
    setEnquiry({ ...enquiry, [name]: value });
  };

  const handleEnquiryOk = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setConfirmLoading(true);

    const isFormValid = validateEnquiryFormData(); // Validate the form data
    // console.log("hello", isFormValid);
     if (isFormValid) {
    // Submit the form if validation passes
    EnquiryAPI.createEnquiry(enquiry)
      .then((result) => {
        if (result.data.status) {
          notify_success(result.data.message);
          setOpen(false);
          setConfirmLoading(false);
          resetEnquiryFormData();
        }
      })
      .catch((error) => {
        notify_error("Error submitting form: " + error.message);
        setConfirmLoading(false);
      });
     } else {
         setConfirmLoading(false);
         console.log("hello sad");
     }
  };

  const handleEnquiryCancel = () => {
    setOpen(false);
    // handleRefresh();
  };

  const isValidEmail = (email) => {
    const emailRegex =  /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailRegex.test(email);
  };

  const isValidMobile = (mobile) => {
    const emailRegex =  /^[6-9]\d{9}$/;
    return emailRegex.test(mobile);
  };

  const validateEnquiryFormData = () => {
    /*let isFormValid = true;
    // Perform validation checks for required fields
    if (
      !enquiry.program ||
      !enquiry.name ||
      !enquiry.mobile_number ||
      !enquiry.form_location
    ) {
      notify_error("Please fill in all required fields.");
      isFormValid = false;
    }*/
      if (!enquiry.name.trim()) {
        notify_error("Name is required");
        return false;
        
      }
      if (!enquiry.email.trim()) {
        notify_error("Email is required");
        return false;
      }
      if (!isValidEmail(enquiry.email)) {
        notify_error("Invalid email format");
        return false;
      }
      if (!isValidMobile(enquiry.mobile_number)) {
        notify_error("Invalid Mobile number");
        return false;
      }
      if (!enquiry.mobile_number.trim()) {
        notify_error("Mobile Number required");
        return false;
      }
      if (!enquiry.form_location.trim()) {
        notify_error("Enquiry type is required");
        return false;
      }

    let check = 0;
    // console.log("check 1");
    if (enquiry.form_location == "walk-in") {
      Object.entries(enquiry)
        .filter(
          ([key, value]) =>
            key !== "reference" &&
            key !== "source" &&
            key !== "state" &&
            key !== "city" &&
            key !== "email"
        )
        .forEach(([key, value]) => {
          if ((key && value === undefined) || value === null || value === "") {
            notify_error("Please enter " + key.split("_").join(" "));
            check = 1;
            return false
          }
        });
      // console.log("check 2");
    }
     else if (enquiry.form_location == "telephonic") {
      Object.entries(enquiry)
        .filter(
          ([key, value]) =>
            key !== "reference" &&
            key !== "state" &&
            key !== "city" &&
            key !== "email"
        )
        .forEach(([key, value]) => {
          if ((key && value === undefined) || value === null || value === "") {
            notify_error("Please enter " + key.split("_").join(" "));
            check = 1;
            return false;
          }
        });
      // console.log("check 3");
    }

    return true;

    // if (check !== 1) {
    //   if (validateEnquiryMobileNumber(enquiry.mobile_number)) {
    //     setFormValidate(true);
    //   } else {
    //     notify_error("Please enter a valid mobile number");
    //   }
    // }
  };

  const isEnquiryEmail = (email) => {
    let regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regex.test(email)) {
      return false;
    } else {
      return true;
    }
  };

  const validateEnquiryMobileNumber = (mobileNumber) => {
    const indianRegex = /^[6-9]\d{9}$/;
    if (indianRegex.test(mobileNumber)) {
      return true;
    } else {
      return false;
    }
  };

  const resetEnquiryFormData = () => {
    setEnquiry({
      program: "",
      name: "",
      email: "",
      mobile_number: "",
      form_location: "",
      enquiry_type: "",
      source: "",
      reference: "",
      state: "",
      city: "",
    });
  };

  return (
    <>
      <Modal
        // title="Walk-In Form"
        open={open}
        footer={null}
        // onOk={handleEnquiryOk}
        // confirmLoading={confirmLoading}
        onCancel={handleEnquiryCancel}
        style={{
          marginBlock: "auto",
          paddingTop: "20px",
          borderRadius: "44px",
          // backgroundColor:"white",
          fontFamily: "Inter",
        }}
      >
        <Form className="row">
          <div className="mb-3  col-md-10 mx-auto bg-transparent">
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "32px",
                lineHeight: "38px",
                fontWeight: "700",
              }}
            >
              New Enquiry Form
            </h2>
            <div className=" form-group">
              <label htmlFor="form_location">
                Enquiry Types<span style={{ color: "red" }}>*</span>
              </label>
              <Form.Select
                style={{
                  border: "1px solid",
                  borderRadius: "12px",
                  padding: "15px",
                  color: "black",
                }}
                className="form-control border-secondary custom-dropdown"
                id="form_location"
                name="form_location"
                onChange={handleOnEnquiryChange}
              >
                <option selected disabled value={enquiry.program}>
                  Select Your Enquiry Type
                </option>

                <option value="walk-in">WALK_IN</option>
                <option value="telephonic">TELEPHONIC</option>
                <option value="existing-student">EXISTING STUDENT</option>
              </Form.Select>
              {/* <i className="pi pi-chevron-down" style={{position:"absolute", top:"18%" , bottom:"0px" , left:"80%"}}/> */}
              <span id="show_program" class="err">
                Please Select Enquiry Type
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="program">
                Program<span style={{ color: "red" }}>*</span>
              </label>
              <Form.Select
                className="form-control border-secondary custom-dropdown"
                style={{
                  padding: "15px",
                  border: "1px solid",
                  borderRadius: "12px",
                  color: "black",
                }}
                id="program"
                name="program"
                onChange={handleOnEnquiryChange}
              >
                <option selected disabled value={enquiry.program}>
                  Select Your Program
                </option>
                <option value="bba">BBA</option>
                <option value="bca">BCA</option>
                <option value="b.com(h)">B.COM</option>
                <option value="mba">MBA</option>
                <option value="b.ed">B.ED</option>
                <option value="mca">MCA</option>
                <option value="ba.llb">B.A.LLB</option>
                <option value="llb">LLB</option>
                <option value="b.com.llb">B.Com LLB</option>
                <option value="D.El.Ed">D.El.Ed</option>
                <option value="LLM">LLM</option>
              </Form.Select>
              {/* <i className="pi pi-chevron-down" style={{position:"absolute", top:"33%" , bottom:"0px" , left:"80%"}}/> */}
              <span id="show_program" class="err">
                Please Select Program
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="name">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                style={{ border: "2px solid", borderRadius: "12px" }}
                className="form-control border-secondary"
                id="name"
                name="name"
                placeholder="Enter name"
                onChange={handleOnEnquiryChange}
                value={enquiry.name}
              />
              <span id="show_name" class="err">
                Please Enter Name
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="email">
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                style={{ border: "2px solid", borderRadius: "12px" }}
                className="form-control border-secondary"
                id="email"
                name="email"
                placeholder="Enter email"
                onChange={handleOnEnquiryChange}
                value={enquiry.email}
              />
              {/* <span id="show_email" class="err">
            Please Enter Email
          </span> */}
            </div>

            <div className="form-group">
              <label htmlFor="mobile">
                Mobile<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                style={{ border: "2px solid", borderRadius: "12px" }}
                className="form-control border-secondary"
                id="mobile_number"
                name="mobile_number"
                placeholder="Mobile number"
                onChange={handleOnEnquiryChange}
                value={enquiry.mobile_number}
              />
              <span id="show_mobile_number" class="err">
                Please Enter Mobile Number
              </span>
            </div>
            {isTelephonic && (
              <div className="form-group">
                <label htmlFor="source">Source</label>
                <input
                  type="text"
                  style={{ border: "2px solid", borderRadius: "12px" }}
                  className="form-control border-secondary"
                  id="source"
                  name="source"
                  placeholder="Source"
                  onChange={handleOnEnquiryChange}
                  value={enquiry.source}
                />
                <span id="show_mobile_number" class="err">
                  Please Enter Source of enquiry
                </span>
              </div>
            )}
            {isTelephonic && (
              <div className="form-group">
                <label htmlFor="reference">
                  References <small>(if any?)</small>
                </label>
                <input
                  type="text"
                  style={{ border: "2px solid", borderRadius: "12px" }}
                  className="form-control border-secondary"
                  id="reference"
                  name="reference"
                  placeholder="Reference "
                  onChange={handleOnEnquiryChange}
                  value={enquiry.reference}
                />
                <span id="show_mobile_number" class="err">
                  Please Enter Reference
                </span>
              </div>
            )}

            <div className="d-flex gap-3 align-items-center justify-content-center ">
              <div className="">
                <button
                  className=" py-2"
                  onClick={handleEnquiryOk}
                  style={{
                    backgroundColor: "#663399",
                    borderRadius: "8px",
                    color: "white",
                    fontFamily: "Inter",
                    border: "none",
                    fontSize: "15px",
                    fontWeight: "700",
                    paddingInline: "40px",
                    border: " 2px solid #663399",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.transform = "scale(1.02)")
                  }
                  onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                >
                  Submit
                </button>
              </div>
              <div className="">
                <button
                  className=" py-2 spButton2"
                  onClick={handleEnquiryCancel}
                  style={{
                    // backgroundColor: "white",
                    // borderRadius: "12px",
                    // color: "black",
                    fontFamily: "Inter",
                    // border: "2px solid",
                    // borderColor: "#C3BDBD",
                    fontSize: "15px",
                    fontWeight: "700",
                    paddingInline: "40px",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default CreateEnquiry;
