import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { } from "date-fns/locale";
import { Pagination, Modal } from "antd";
import JobFairApi from "../../utils/api/JobFairApi";
import { serverAddress } from "../../constant";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { DownloadOutlined } from "@ant-design/icons";
import { notify_error, notify_success } from "../Notify";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import { useDispatch } from "react-redux";
import {
  Image,
  InputGroup,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { Badge } from "antd";

const JobFairDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { top_loader } = bindActionCreators(actionCreators, dispatch);

  const [loading, setLoading] = useState(false);
  const [recperpage, SetRecPerPage] = useState(25);
  const [activepage, SetActivePage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [search, SetSearch] = useState();
  const sno1 = recperpage * (activepage - 1);
  const sno2 = recperpage * (activepage - 1);
  const sno3 = recperpage * (activepage - 1);

  // Retrieve the active tab from localStorage or default to "link-0"
  const initialActiveTab = localStorage.getItem("activeTab") || "link-0";
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const [reqforToggler, setreqforToggler] = useState(1);

  const [Compdata, setCompDate] = useState([]);
  const [ipemStudent, setIpemStudent] = useState([]);
  const [ExternalStudents, setExternalStudents] = useState([]);

  const [candidateId, setCandidateId] = useState("");
  const [open, setOpen] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleRowClick = (student) => {
    setSelectedStudent(student);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setSelectedStudent(null);
  };

  let timer;

  useEffect(() => {
    // Dynamically inject CSS for scrollbar hiding, only for the sidebar
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
      .custom-sidebar::-webkit-scrollbar { display: none; }  /* For Chrome, Safari */
      .custom-sidebar { -ms-overflow-style: none; scrollbar-width: none; }  /* For IE, Firefox */
    `;
    document.head.appendChild(styleElement);

    // Clean up the style when the component is unmounted
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
    localStorage.setItem("activeTab", key); // Save the active tab in localStorage

    if (key === "link-0") {
      setreqforToggler(0);
    } else if (key === "link-1") {
      setreqforToggler(1);
    } else if (key === "link-2") {
      setreqforToggler(2);
    }
  };

  const getCompanyDetails = async (params) => {
    setLoading(true);
    JobFairApi.getallCompanyRegistration(params).then((result) => {
      if (result.data.status) {
        // console.log(result.data);
        setCompDate(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
        // // console.log(data);
      } else {
        setCompDate([]);
        setLoading(false);
      }
    });
  };

  const getStudentDetails = async (params) => {
    setLoading(true);
    JobFairApi.getAllStudentRegistration(params).then((result) => {
      if (result.data.status) {
        // console.log(result.data);
        setIpemStudent(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
      } else {
        setIpemStudent([]);
        setLoading(false);
      }
    });
  };

  const getStudentExternalDetails = async (params) => {
    setLoading(true);
    JobFairApi.getExternalStudentRegistration(params).then((result) => {
      if (result.data.status) {
        console.log(result.data);
        setExternalStudents(result.data.data);
        setTotalData(result.data.total);
        setLoading(false);
      } else {
        setIpemStudent([]);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    let fetchDataFunction = null;

    switch (activeTab) {
      case "link-0":
        setreqforToggler(0);
        fetchDataFunction = getStudentDetails;
        break;
      case "link-1":
        setreqforToggler(1);
        fetchDataFunction = getStudentExternalDetails;
        break;
      case "link-2":
        setreqforToggler(2);
        fetchDataFunction = getCompanyDetails;
        break;
      default:
        break;
    }

    if (fetchDataFunction) {
      fetchDataFunction({ page: activepage, limit: recperpage });
    }
  }, [activepage, recperpage, activeTab]);

  function handlePageChange(page) {
    SetActivePage(page);
    // getAllUllas({ page: page, limit: recperpage });
  }

  // ipem student search

  const handleipemstudentSearch = async (searchterm) => {

    const res = await JobFairApi.SearchIpemStudent(
      { search: searchterm },
      activepage,
      recperpage
    );
    setIpemStudent(res.data.data);
    setTotalData(res.data.total);
    SetActivePage(1);
    // console.log(res.data)


  };

  //ipem external student search
  const handleExternalStudentSearch = async (searchterm) => {
    console.log(searchterm);
    const res = await JobFairApi.SearchExternalStudent(
      { search: searchterm },
      activepage,
      recperpage
    );

    setExternalStudents(res.data.data);
    setTotalData(res.data.total);
    SetActivePage(1);
    // console.log(res.data)

  };

  // company search
  const handleCompanySearch = async (searchterm) => {
    const res = await JobFairApi.SearchJobfairCompany(
      { search: searchterm },
      activepage,
      recperpage
    )
    setCompDate(res.data.data);
    setTotalData(res.data.total);
    SetActivePage(1);
    // console.log(res.data)
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    console.log(activeTab);
    SetSearch(value);
    console.log(value);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (value.trim() === "") {
        if (activeTab === 'link-0'){
          getStudentDetails({ page: activepage, limit: recperpage });
        }else if (activeTab === 'link-1') {
          getStudentExternalDetails({ page: activepage, limit: recperpage });
        }else if (activeTab === 'link-2') {
          getCompanyDetails({ page: activepage, limit: recperpage });
        }
      } else {
        if (activeTab === 'link-0') {

          handleipemstudentSearch(value.trim());
        }
        else if (activeTab === 'link-1') {

          handleExternalStudentSearch(value.trim());
        }
        else if (activeTab === 'link-2') {

          handleCompanySearch(value.trim());
        }

      }
    }, 300);

  };

  const handleRefresh = () => {
    // Clear search
    SetSearch("");
    // setIsSearch(false);
    // Fetch data again
    if (activeTab === 'link-0'){
      getStudentDetails({ page: activepage, limit: recperpage });
    }else if (activeTab === 'link-1') {
      getStudentExternalDetails({ page: activepage, limit: recperpage });
    }else if (activeTab === 'link-2') {
      getCompanyDetails({ page: activepage, limit: recperpage });
    }
    //getStudentDetails({ page: activepage, limit: recperpage });
    //getCompanyDetails({ page: activepage, limit: recperpage });
    //getStudentExternalDetails({ page: activepage, limit: recperpage });
  };

 /* function handleIpemStudentDownload() {
    JobFairApi.ExportIpemStudent({ search: search }, activepage, recperpage)
      .then((response) => {
        if (response.data && response.data.link) {
          // Download the TXT file
          const txtLink = response.data.link;

          fetch(txtLink)
            .then((txtResponse) => txtResponse.text())
            .then((txtContent) => {
              // Convert TXT content to CSV format
              const csvContent = convertTxtToCsv(txtContent);

              // Create a Blob with the CSV content
              const blob = new Blob([csvContent], { type: "text/csv" });

              // Create a temporary link to trigger the download
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = URL.createObjectURL(blob);
              a.download = "ipemStudent.csv";

              // Trigger the download
              document.body.appendChild(a);
              a.click();

              // Clean up
              document.body.removeChild(a);
            })
            .catch((txtError) => {
              notify_error(
                "Error while fetching or converting TXT file:",
                txtError
              );
            });
        } else {
          notify_error("Invalid response format. Download link not found.");
        }
      })
      .catch((error) => {
        notify_error("Download error:", error);
      });
  } */
      function convertResultsToCsv(results) {
        if (!Array.isArray(results) || results.length === 0) {
          return "No data available";
        }
      
        // Extract headers from object keys
        const headers = Object.keys(results[0]).join(",") + "\n";
      
        // Convert each row into a CSV format
        const rows = results
          .map((row) =>
            Object.values(row)
              .map((value) => `"${value ?? 'Not Provided'}"`) // Handle undefined values
              .join(",")
          )
          .join("\n");
      
        return headers + rows;
      }
      function handleIpemStudentDownload() {
        JobFairApi.ExportIpemStudent({ search: search }, activepage, recperpage)
          .then((response) => {
            if (response.data && response.data.results) {
              const csvContent = convertResultsToCsv(response.data.results); // Convert data to CSV
      
              // Create a Blob with the CSV content
              const blob = new Blob([csvContent], { type: "text/csv" });
      
              // Create a temporary link to trigger the download
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = URL.createObjectURL(blob);
              a.download = "ipemStudent.csv";
      
              // Trigger the download
              document.body.appendChild(a);
              a.click();
      
              // Clean up
              document.body.removeChild(a);
            } else {
              notify_error("Invalid response format. No data available.");
            }
          })
          .catch((error) => {
            notify_error("Download error:", error);
          });
      }

  function handleExternalStudentDownload() {
    JobFairApi.ExportexternalStudent({ search: search }, activepage, recperpage)
      .then((response) => {
        if (response.data && response.data.link) {
          // Download the TXT file
          const txtLink = response.data.link;

          fetch(txtLink)
            .then((txtResponse) => txtResponse.text())
            .then((txtContent) => {
              // Convert TXT content to CSV format
              const csvContent = convertTxtToCsv(txtContent);

              // Create a Blob with the CSV content
              const blob = new Blob([csvContent], { type: "text/csv" });

              // Create a temporary link to trigger the download
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = URL.createObjectURL(blob);
              a.download = "externalStudents.csv";

              // Trigger the download
              document.body.appendChild(a);
              a.click();

              // Clean up
              document.body.removeChild(a);
            })
            .catch((txtError) => {
              notify_error(
                "Error while fetching or converting TXT file:",
                txtError
              );
            });
        } else {
          notify_error("Invalid response format. Download link not found.");
        }
      })
      .catch((error) => {
        notify_error("Download error:", error);
      });
  }

  function handleCompanyDownload() {
    JobFairApi.ExportCompany({ search: search }, activepage, recperpage)
      .then((response) => {
        if (response.data && response.data.link) {
          // Download the TXT file
          const txtLink = response.data.link;

          fetch(txtLink)
            .then((txtResponse) => txtResponse.text())
            .then((txtContent) => {
              // Convert TXT content to CSV format
              const csvContent = convertTxtToCsv(txtContent);

              // Create a Blob with the CSV content
              const blob = new Blob([csvContent], { type: "text/csv" });

              // Create a temporary link to trigger the download
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = URL.createObjectURL(blob);
              a.download = "Jobfaircompany.csv";

              // Trigger the download
              document.body.appendChild(a);
              a.click();

              // Clean up
              document.body.removeChild(a);
            })
            .catch((txtError) => {
              notify_error(
                "Error while fetching or converting TXT file:",
                txtError
              );
            });
        } else {
          notify_error("Invalid response format. Download link not found.");
        }
      })
      .catch((error) => {
        notify_error("Download error:", error);
      });
  }

  function convertTxtToCsv(txtContent) {
    // Implement the logic to convert the TXT content to CSV.
    // This depends on the structure and format of your TXT data.
    // You may need to split and process the text to create CSV rows and columns.
    // Here's a simple example assuming a space-separated format:

    const lines = txtContent.split("\n");
    const csvRows = [];

    for (const line of lines) {
      const columns = line.split(" "); // Change the separator based on your data format
      csvRows.push(columns.join(" "));
    }

    return csvRows.join("\n");
  }

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    console.log(candidateId, transactionId);

    JobFairApi.confirmPaymentLaw(candidateId, transactionId)
      .then((result) => {
        top_loader(true, 45);
        getStudentExternalDetails({ page: activepage, limit: recperpage });

        setCandidateId("");
        setTransactionId("");
        notify_success("Student Payment Status Updated Successfully.");
        setOpen(false);
      })
      .catch((err) => {
        notify_error(err?.message || err);
      });
  };

  return (
    <>
      <Container
        fluid
        className="p-0 custom-sidebar"
        style={{
          backgroundColor: "#F2F5F7",
          fontFamily: "Inter",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <Row>
          <Tabs
            id="wrapper"
            style={{
              overflow: "hidden",
              borderBottom: "2px solid #070261",
              borderTop: "2px solid #eee !important",
              marginTop: "6rem",
            }}
            activeKey={activeTab}
            onSelect={handleTabChange}
            fill
          >
            <Tab
              eventKey="link-1"
              title="External Student Registration"
              tabClassName="External-students"
            >
              {/* Content for External Student Registration */}
              <Row style={{ marginInline: "10px" }}>
                <Col lg={12}>
                  {/* Search , export and refresh */}

                  <div
                    lg={12}
                    className="bg-white mx-0 rounded"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    {/* Search button */}
                    <div style={{ marginLeft: "12px", width: "25%" }}>
                      {" "}
                      {/* Set width to 20% */}
                      <InputGroup
                        className="my-2"
                        style={{
                          borderRadius: "8px", // Rounded corners
                          border: "1px solid #D1D5DB", // Light border
                          backgroundColor: "#F8F9FB", // Light background
                          height: "43px", // Fixed height
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#F8F9FB",
                            paddingLeft: "11px",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            height: "100%", // Ensures the icon container takes the full height
                          }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          {/* <img src="/assets/images/dashboard/search.png" alt="search icon" /> */}
                        </div>

                        <Form.Control
                          placeholder="Search In Job-Fair Table by Name ,Email and Contact Number"
                          aria-label="Search"
                          name="search"
                          style={{
                            backgroundColor: "#F8F9FB",
                            border: "none", // No inner border
                            fontFamily: "Inter",
                            paddingLeft: "0px",
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                            fontSize: "16px", // Adjust font size
                            color: "#6B7280", // Placeholder color
                            height: "100%", // Ensures the input field takes the full height
                            fontSize: "12px",
                          }}
                          onChange={handleSearchChange}
                          value={search}
                        />
                      </InputGroup>
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                      {/* Button Group */}
                      <div className="d-flex align-items-center mx-1 mt-2 mt-sm-0">
                        {/* Export/download */}
                        <div
                          className="mx-2"
                          style={{ padding: "0", margin: "0" }}
                        >
                          <Button
                            onClick={() => handleExternalStudentDownload()}
                            style={{
                              backgroundColor: "#663399",
                              borderColor: "#663399",
                              width: "auto",
                              padding: "8px 12px", // Reduced padding for smaller buttons
                              height: "38px", // Slightly reduced height
                              whiteSpace: "nowrap",
                              borderRadius: "8px",
                            }}
                          >
                            <i className="fa-solid fa-download me-2"></i>Export
                          </Button>
                        </div>

                        {/* Refresh Icon */}
                        <div className="d-flex align-items-center mx-2">
                          <RefreshOutlinedIcon
                            fontSize="large"
                            color="disabled"
                            cursor="pointer"
                            onClick={handleRefresh}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="jobfair-Headings">
                    {" "}
                    <h2>External Student Registration</h2>
                  </div>

                  <div className="py-2 mt-1">
                    <div
                      className="maintable "
                      id="table-container"
                      style={{ height: "auto", width: "auto" }}
                    >
                      <table className="col-md-12 enquiryTable">
                        <thead className="bg-transparent">
                          <tr className="tableHeading tableHeadAllEnquiry bg-transparent">
                            <th>S.No</th>
                            <th style={{ width: "10%" }}>Student name</th>
                            <th style={{ width: "10%" }}>Selected stream</th>
                            <th style={{ width: "10%" }}>Passing year </th>
                            <th>Email address</th>
                            <th>Father's name</th>
                            <th style={{ width: "10%" }}>Contact number</th>
                            <th>College</th>
                            <th style={{ width: "8%" }}>Student id</th>
                          </tr>
                        </thead>

                        <tbody className="mt-1">
                          {ExternalStudents &&
                            ExternalStudents.length > 0 &&
                            ExternalStudents.map((item, index) => {
                              return (
                                <tr className="tableRows">
                                  <td>
                                    {!item.repeat && (
                                      <span>{sno1 + index + 1}</span>
                                    )}
                                    {item.repeat && (
                                      <span
                                        style={{
                                          display: "flex",
                                          // position:'relative',
                                          justifyContent: "space-around",
                                          alignItems: "",
                                        }}
                                      >
                                        {sno1 + index + 1}
                                        <Badge.Ribbon
                                          style={{
                                            fontSize: "8px",
                                            right: "-20px",
                                            position: "absolute",
                                          }}
                                          color="#f50"
                                          placement="end"
                                          text="DUP"
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td>{item.studentName}</td>
                                  <td>{item.selectStream}</td>
                                  <td>{item.passingYear}</td>
                                  <td>{item.email_address}</td>
                                  <td>{item.fatherName}</td>
                                  <td>{item.studentContactNumber}</td>
                                  <td>{item.collegeName}</td>
                                  <td>
                                    {/* Add a button to trigger the download */}
                                    <button
                                      style={{
                                        backgroundColor: "#663399",
                                        border: "none",
                                        color: "white",
                                        fontWeight: "400",
                                        fontSize: "13px",
                                        lineHeight: "35px",
                                        borderRadius: "8px",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(
                                          `${serverAddress}/jobfair/registration/external-student/get/preview?file_path=${item.studentIDCard}`
                                        );
                                      }}
                                    >
                                      Preview
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Tab>

            <Tab
              eventKey="link-0"
              title="Student Registration"
              tabClassName="student-registration"
            >
              {/* Content for Student Registration */}
              <Row style={{ marginInline: "10px" }}>
                <Col lg={12}>
                  {/* Search , export and refresh */}
                  <div
                    lg={12}
                    className="bg-white mx-0 rounded"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    {/* Search button */}
                    <div style={{ marginLeft: "12px", width: "25%" }}>
                      {" "}
                      {/* Set width to 20% */}
                      <InputGroup
                        className="my-2"
                        style={{
                          borderRadius: "8px", // Rounded corners
                          border: "1px solid #D1D5DB", // Light border
                          backgroundColor: "#F8F9FB", // Light background
                          height: "43px", // Fixed height
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#F8F9FB",
                            paddingLeft: "11px",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            height: "100%", // Ensures the icon container takes the full height
                          }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          {/* <img src="/assets/images/dashboard/search.png" alt="search icon" /> */}
                        </div>

                        <Form.Control
                          placeholder="Search In Job-Fair Table by Name ,Email and Contact Number"
                          aria-label="Search"
                          name="search"
                          style={{
                            backgroundColor: "#F8F9FB",
                            border: "none", // No inner border
                            fontFamily: "Inter",
                            paddingLeft: "0px",
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                            fontSize: "16px", // Adjust font size
                            color: "#6B7280", // Placeholder color
                            height: "100%", // Ensures the input field takes the full height
                            fontSize: "12px",
                          }}
                          onChange={handleSearchChange}
                          value={search}
                        />
                      </InputGroup>
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                      {/* Button Group */}
                      <div className="d-flex align-items-center mx-1 mt-2 mt-sm-0">
                        {/* Export/download */}
                        <div
                          className="mx-2"
                          style={{ padding: "0", margin: "0" }}
                        >
                          <Button
                            onClick={() => handleIpemStudentDownload()}
                            style={{
                              backgroundColor: "#663399",
                              borderColor: "#663399",
                              width: "auto",
                              padding: "8px 12px", // Reduced padding for smaller buttons
                              height: "38px", // Slightly reduced height
                              whiteSpace: "nowrap",
                              borderRadius: "8px",
                            }}
                          >
                            <i className="fa-solid fa-download me-2"></i>Export
                          </Button>
                        </div>

                        {/* Refresh Icon */}
                        <div className="d-flex align-items-center mx-2">
                          <RefreshOutlinedIcon
                            fontSize="large"
                            color="disabled"
                            cursor="pointer"
                            onClick={handleRefresh}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="jobfair-Headings">
                    {" "}
                    <h2> Student Registration</h2>
                  </div>{" "}
                  <div
                    className="py-2"
                    style={{ marginTop: "50px", height: "auto" }}
                  >
                    <div
                      className="maintable p-0 "
                      id="table-container"
                      style={{ height: "auto", width: "auto" }}
                    >
                      <table className="col-md-12 enquiryTable">
                        <thead className="tableHeadAllEnquiry">
                          <tr className="tableHeading">
                            <th>S.No</th>
                            <th>Student name</th>
                            <th>Selected stream</th>
                            <th>Email address</th>
                            <th>Student contact</th>
                          </tr>
                        </thead>

                        <tbody className="mt-1">
                          {ipemStudent &&
                            ipemStudent.length > 0 &&
                            ipemStudent.map((item, index) => {
                              return (
                                <tr className="tableRows"  onClick={() => handleRowClick(item)} >
                                  <td>
                                    {!item.repeat && (
                                      <span>{sno2 + index + 1}</span>
                                    )}
                                    {item.repeat && (
                                      <span
                                        style={{
                                          display: "flex",
                                          // position:'relative',
                                          justifyContent: "space-around",
                                          alignItems: "",
                                        }}
                                      >
                                        {sno2 + index + 1}
                                        <Badge.Ribbon
                                          style={{
                                            fontSize: "8px",
                                            right: "-20px",
                                            position: "absolute",
                                          }}
                                          color="#f50"
                                          placement="end"
                                          text="DUP"
                                        />
                                      </span>
                                    )}
                                  </td>
                                  <td>{item.studentName}</td>
                                  <td>{item.selectStream}</td>
                                  <td>{item.email_address}</td>
                                  <td>{item.studentContactNumber}</td>
                                  {/* <td>{item.studentIDCard}</td> */}

                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <Modal title="Student Details" open={isModalVisible} onCancel={handleClose} footer={null}>
                      {selectedStudent && (
                                  <div>
                                  <p><strong>Name:</strong> {selectedStudent.studentName || "Not Provided"}</p>
                                  <p><strong>Stream:</strong> {selectedStudent.selectStream || "Not Provided"}</p>
                                  <p><strong>Passing Year:</strong> {selectedStudent.passingYear || "Not Provided"}</p>
                                  <p><strong>Email:</strong> {selectedStudent.email_address || "Not Provided"}</p>
                                  <p><strong>Father's Name:</strong> {selectedStudent.fatherName || "Not Provided"}</p>
                                  <p><strong>Contact Number:</strong> {selectedStudent.studentContactNumber || "Not Provided"}</p>
                                  <p><strong>College Name:</strong> {selectedStudent.collegeName || "Not Provided"}</p>
                                  <p><strong>Date of Birth:</strong> {selectedStudent.dob || "Not Provided"}</p>
                                  <p><strong>Gender:</strong> {selectedStudent.gender || "Not Provided"}</p>
                                  <p><strong>District:</strong> {selectedStudent.city || "Not Provided"}</p>
                                  <p><strong>State:</strong> {selectedStudent.state || "Not Provided"}</p>
                                  <p><strong>Highest Qualification:</strong> {selectedStudent.qualification || "Not Provided"}</p>
                                  <p><strong>Address:</strong> {selectedStudent.address || "Not Provided"}</p>
                                  <p><strong>Pincode:</strong> {selectedStudent.pincode || "Not Provided"}</p>
                                  <p><strong>Religion:</strong> {selectedStudent.religion || "Not Provided"}</p>
                                  <p><strong>Category:</strong> {selectedStudent.category || "Not Provided"}</p>
                                  <p><strong>Session:</strong> {selectedStudent.session || "Not Provided"}</p>
                                </div>
                      )}
                    </Modal>
                  </div>
                </Col>
              </Row>
            </Tab>

            <Tab
              eventKey="link-2"
              title="Company Registration"
              tabClassName="company-registration"
            >
              {/* Content for Company Registration */}
              <Row style={{ marginInline: "10px" }}>
                <Col lg={12}>
                  {/* Search , export and refresh */}
                  <div
                    lg={12}
                    className="bg-white mx-0 rounded"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    {/* Search button */}
                    <div style={{ marginLeft: "12px", width: "25%" }}>
                      {" "}
                      {/* Set width to 20% */}
                      <InputGroup
                        className="my-2"
                        style={{
                          borderRadius: "8px", // Rounded corners
                          border: "1px solid #D1D5DB", // Light border
                          backgroundColor: "#F8F9FB", // Light background
                          height: "43px", // Fixed height
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#F8F9FB",
                            paddingLeft: "11px",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            height: "100%", // Ensures the icon container takes the full height
                          }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          {/* <img src="/assets/images/dashboard/search.png" alt="search icon" /> */}
                        </div>

                        <Form.Control
                          placeholder="Search In Job-Fair Table by Name ,Email and Contact Number"
                          aria-label="Search"
                          name="search"
                          style={{
                            backgroundColor: "#F8F9FB",
                            border: "none", // No inner border
                            fontFamily: "Inter",
                            paddingLeft: "0px",
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                            fontSize: "16px", // Adjust font size
                            color: "#6B7280", // Placeholder color
                            height: "100%", // Ensures the input field takes the full height
                            fontSize: "12px",
                          }}
                          onChange={handleSearchChange}
                          value={search}
                        />
                      </InputGroup>
                    </div>

                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                      {/* Button Group */}
                      <div className="d-flex align-items-center mx-1 mt-2 mt-sm-0">
                        {/* Export/download */}
                        <div
                          className="mx-2"
                          style={{ padding: "0", margin: "0" }}
                        >
                          <Button
                            onClick={() => handleCompanyDownload()}
                            style={{
                              backgroundColor: "#663399",
                              borderColor: "#663399",
                              width: "auto",
                              padding: "8px 12px", // Reduced padding for smaller buttons
                              height: "38px", // Slightly reduced height
                              whiteSpace: "nowrap",
                              borderRadius: "8px",
                            }}
                          >
                            <i className="fa-solid fa-download me-2"></i>Export
                          </Button>
                        </div>

                        {/* Refresh Icon */}
                        <div className="d-flex align-items-center mx-2">
                          <RefreshOutlinedIcon
                            fontSize="large"
                            color="disabled"
                            cursor="pointer"
                            onClick={handleRefresh}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="jobfair-Headings">
                    {" "}
                    <h2> Company Registration</h2>
                  </div>{" "}
                  <div className="py-2" style={{ marginTop: "10px" }}>
                    <div
                      className="maintable p-0 "
                      id="table-container"
                      style={{ height: "auto", width: "auto" }}
                    >
                      <table className="col-md-12 enquiryTable">
                        <thead className="tableHeadAllEnquiry">
                          <tr className="tableHeading">
                            <th>S.No</th>
                            <th style={{ width: "15%" }}>Organization name</th>
                            <th style={{ width: "20%" }}>
                              Organization address
                            </th>
                            <th>Email address</th>
                            <th style={{ width: "15%" }}>Contact person</th>
                            <th style={{ width: "25%" }}>Role hiring</th>
                            <th style={{ width: "10%" }}>Contact number</th>
                            <th style={{ width: "15%" }}>Job description</th>
                          </tr>
                        </thead>

                        <tbody className="mt-1">
                          {Compdata &&
                            Compdata.length > 0 &&
                            Compdata.map((item, index) => {
                              return (
                                <tr className="tableRows">
                                  <td>
                                    {!item.repeat && (
                                      <span>{sno3 + index + 1}</span>
                                    )}
                                    {item.repeat && (
                                      <span
                                        style={{
                                          display: "flex",
                                          // position:'relative',
                                          justifyContent: "space-around",
                                          alignItems: "",
                                        }}
                                      >
                                        {sno3 + index + 1}
                                        <Badge.Ribbon
                                          style={{
                                            fontSize: "8px",
                                            right: "-20px",
                                            position: "absolute",
                                          }}
                                          color="#f50"
                                          placement="end"
                                          text="DUP"
                                        />
                                      </span>
                                    )}
                                  </td>

                                  <td>{item.organizationName}</td>
                                  <td>{item.organizationAddress}</td>
                                  <td>{item.emailAddress}</td>
                                  <td>{item.contactPersonName}</td>
                                  <td>{item.roleHiring}</td>
                                  <td>{item.contactNumber}</td>
                                  {/* <td>{item.jobDescription}</td> */}
                                  <button
                                    style={{
                                      backgroundColor: "#663399",
                                      border: "none",
                                      color: "white",
                                      fontWeight: "400",
                                      fontSize: "13px",
                                      lineHeight: "35px",
                                      borderRadius: "8px",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      window.open(
                                        `${serverAddress}/jobfair/registration/company/get/preview?file_path=${item.jobDescription}`
                                      );
                                    }}
                                  >
                                    Preview
                                  </button>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Row>

        {/* company's registration */}

        {/* <div className="tableEnd"></div> */}
        <div className="col-md-12 form-inline py-2 tableFooter d-flex gap-5 align-items-center">
          <div className="col-md-4 d-flex gap-2 align-baseline">
            <label
              className="control-label col-md-4"
              style={{
                fontWeight: "700",
                paddingTop: "5px",
                paddingLeft: "25px",
              }}
            >
              Rows per page :
            </label>
            {/* <i className="pi pi-chevron-down" style={{position:"absolute" , left:"92%", top:"15%"}}/> */}
            <Form.Select
              className="form-control "
              onChange={(event) => SetRecPerPage(event.target.value)}
              // onChange={(event) => applyFilter(event)}
              value={recperpage}
              style={{
                border: "solid",
                borderWidth: "thin",
                color: "#000",
              }}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </Form.Select>
          </div>

          <div
            className="col-md-8 d-flex gap-2"
            style={{
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "60px",
            }}
          >
            <Pagination
              total={totalData}
              showSizeChanger={false}
              current={activepage}
              size="small"
              // showQuickJumper
              pageSize={recperpage}
              onChange={(page, pageSize) => {
                SetActivePage(page);
              }}
            />
          </div>
        </div>

        <Modal
          style={{ fontFamily: "Inter" }}
          open={open}
          footer={null}
          onOk={() => handleOk(false)}
          onCancel={() => handleCancel(false)}
        >
          <div className="mb-3  col-md-10 mx-auto bg-transparent">
            <h2
              style={{
                fontFamily: "Inter",
                fontSize: "24px",
                lineHeight: "38px",
                fontWeight: "700",
              }}
            >
              Transaction Id Verification
            </h2>
            <div className="form-group mt-4">
              <label htmlFor="last_name">Transaction Id</label>
              <input
                type="text"
                style={{ border: "2px solid", borderRadius: "12px" }}
                className="form-control border-secondary"
                id="transactionId"
                name="transactionId"
                placeholder="Please Enter Transaction Id"
                // onChange={handleOnChange}
                value={transactionId}
              />
              <span id="show_name" class="err">
                Please Enter Last Name
              </span>
            </div>

            <div className="text-center">
              <button
                className=" py-2 "
                onClick={handleOk}
                style={{
                  backgroundColor: "#2B73FF",
                  borderRadius: "12px",
                  color: "white",
                  fontFamily: "Inter",
                  border: "none",
                  fontSize: "15px",
                  fontWeight: "700",
                  paddingInline: "40px",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default JobFairDetails;
